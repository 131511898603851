import { useEffect, useMemo, useState } from 'react'
import md5 from 'md5'
import querystring from 'query-string'
import { Image } from './chakra/Image'
import { Tooltip } from './chakra/Tooltip'
import { Box } from './chakra/Box'
import { css } from '@emotion/react'
import theme from '../constants/theme'

function UserProfileImg({ email, imageUrl, showTooltip = false, loadingUser, showRedDot = false, ...imgProps }) {
  const [loading, setLoading] = useState(() => loadingUser)

  useEffect(() => {
    if (!loadingUser) {
      setTimeout(() => setLoading(false), 500)
    }
  }, [loadingUser])

  const src = useMemo(() => {
    if (imageUrl) return imageUrl
    const query = querystring.stringify({
      s: 50,
      r: 'g',
      d: 'retro', // mp
    })
    const base = '//www.gravatar.com/avatar/'
    const formattedEmail = `${email}`.trim().toLowerCase()
    const hash = md5(formattedEmail, { encoding: 'binary' })
    return `${base}${hash}?${query}`
  }, [email, imageUrl, loadingUser])

  // eslint-disable-next-line jsx-a11y/img-redundant-alt
  const ImageComp = (
    <Box style={{ position: 'relative' }}>
      {showRedDot ? (
        <Box
          css={css`
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: ${theme.colors.custom.red};
            position: absolute;
            right: 5px;
            top: 0;
          `}
        />
      ) : null}
      <Image src={loading ? null : src} alt="User picture" {...imgProps} />
    </Box>
  )

  return showTooltip ? (
    <Tooltip
      label={`${
        src.includes('gravatar') ? 'Use gravatar.com' : 'Use social media platform you used in login'
      } to change profile image`}
      aria-label="Gravatar change profile img tooltip"
      hasArrow
      offset={[0, 20]}
    >
      {ImageComp}
    </Tooltip>
  ) : (
    ImageComp
  )
}

export default UserProfileImg
